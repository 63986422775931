/**
 * Timetastic
 * Support page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"

// Helpers
import { animate, trackCta } from "src/helpers"

// Components
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import Header from "src/components/header"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SupportForm from "src/components/supportForm"

// Styles
import "src/styles/templates/support.scss"
import "src/styles/animations/support.scss"

// SEO
const title = "Timetastic Support - How can we help?"
const description =
  "Track Employee PTO using Timetastic, the perfect PTO Calendar and Employee Vacation Tracker for your company."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO
        title={title}
        useTitleTemplate={false}
        description={description}
        hrefLang={{
          EnHref: "https://timetastic.co.uk/support/",
          UsaHref: "https://timetasticapp.com/support/",
          UkHref: "https://timetastic.co.uk/support/",
        }}
      />
      <Layout>
        <Header />
        <FooterCta />
        <main className="c-support-main">
          <section className="c-section u-text-centre u-text-left-mobile c-support-hero">
            <div className="u-inner c-support-hero__inner">
              <div className="is-unanimated">
                <h1 className="h h2">
                  Our{" "}
                  <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                    team
                  </span>
                  , happy to help
                </h1>
              </div>
              <div className="c-support-hero__avatars">
                <img
                  alt="Gareth"
                  className="c-support-hero__avatar is-unanimated"
                  height="192"
                  src="/images/about/gareth.jpg"
                  width="192"
                  loading="eager"
                />
                <img
                  alt="Gary"
                  className="c-support-hero__avatar is-unanimated"
                  height="192"
                  src="/images/about/gary.jpg"
                  width="192"
                  loading="eager"
                />
                <img
                  alt="Matt"
                  className="c-support-hero__avatar is-unanimated"
                  height="192"
                  src="/images/about/matt.jpg"
                  width="192"
                  loading="eager"
                />
                <img
                  alt="Sarah"
                  className="c-support-hero__avatar is-unanimated"
                  height="192"
                  src="/images/about/sarah.jpg"
                  width="192"
                  loading="eager"
                />
              </div>
              <div className="u-inner u-inner--800">
                <div className="is-unanimated">
                  <p>
                    Here you’ll find the user manual for Timetastic and help
                    guides to get you up and running. If you can’t find your
                    answer, just send us a message.
                  </p>
                </div>
              </div>
            </div>

            <div className="u-inner u-inner--l">
              <div className="c-support-boxes is-unanimated">
                <a
                  className="c-support-box"
                  href="https://help.timetastic.co.uk/hc/en-us"
                  rel="noopener"
                  onClick={() => {
                    trackCta("SupportFAQTop")
                  }}
                >
                  <h2 className="h h3 c-support-box__title">Help guides</h2>
                  <p>
                    Want to know how a feature works or how to get the most from
                    Timetastic? Step right in.
                  </p>
                  <div className="c-support-box__link">
                    Read the guides{" "}
                    <svg
                      aria-hidden="true"
                      className="c-more-icon"
                      focussable="false"
                      height="16"
                      viewBox="0 0 20 16"
                      width="20"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </div>
                </a>
                <a
                  className="c-support-box"
                  href="https://help.timetastic.co.uk/hc/en-us"
                  rel="noopener"
                  onClick={() => {
                    trackCta("SupportHelpCentre")
                  }}
                >
                  <h2 className="h h3 c-support-box__title">User manual</h2>
                  <p>
                    New to Timetastic? Browse through the manual to get a feel
                    for how it works.
                  </p>
                  <div className="c-support-box__link">
                    Read the user manual{" "}
                    <svg
                      aria-hidden="true"
                      className="c-more-icon"
                      focussable="false"
                      height="16"
                      viewBox="0 0 20 16"
                      width="20"
                    >
                      <use xlinkHref="#svg-arrow" />
                    </svg>
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section
            className="c-section c-support-form-section is-unanimated"
            id="talk"
          >
            <div className="u-inner u-inner--800 u-text-centre">
              <p>
                Can’t find your answer in the guides?
                <br />
                Reach out to our support team using the form below.
              </p>
              <h2 className="h h2 c-support-form-title">
                Contact our{" "}
                <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                  support
                </span>{" "}
                team
              </h2>
              <p>
                We respond within 2 hours during the working day, often much
                quicker.
                <br />
                That’s 9:30am to 16:30pm GMT, Monday to Friday.
              </p>
              <SupportForm />
            </div>
          </section>
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

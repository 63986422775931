/**
 * Timetastic
 * SupportForm component
 */
import React, { useState } from "react"
import axios from "axios"

// Helpers
import { trackCta, trackFormError } from "src/helpers"

// Styles
import "./supportForm.scss"

const timetasticZendeskUrl =
  "https://createzendeskticket.azurewebsites.net/api/CreateZendeskTicket?code=C5RM60eKb8RJpOzKQr/oWqLsyGaQj0r9jfEYWWPzWmuBQEn/ua4DKw=="

const SupportForm = () => {
  const [email, setEmail] = useState("")
  const [fullName, setFullName] = useState("")
  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")

  const [messageError, setMessageError] = useState(false)
  const [messageSending, setMessageSending] = useState(false)
  const [messageSuccess, setMessageSuccess] = useState(false)

  // Don't let browsers which don't support `window.btoa` (which is IE10 and less)
  // from seeing this form because they can't encrypt Zendesk API calls
  if (typeof document !== "undefined" && typeof window.btoa === undefined) {
    return
  }

  // Reset the form values to try and prevent resending of the ticket
  const resetFormFields = () => {
    // setEmail("")
    // setFullName("")
    // setMessage("")
    // setSubject("")
  };

  // When form submits, send the data to our contact-support.php file which
  // will send the data to Zendesk. Why..? See the README file in this component
  const submitHandler = (event) => {
    event.preventDefault()

    setMessageError(false)
    setMessageSuccess(false)
    setMessageSending(true)

    const data = {
      fullname: fullName,
      email: email,
      subject: subject,
      message: message,
    }


    axios({
      method: "post",
      url: timetasticZendeskUrl,
      data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setMessageSending(false)
        if (response.status >= 200 && response.status <= 299) {
          trackCta("SupportTicket")
          setMessageSuccess(true)
        } else {
          setMessageError(`An error occurred!`)
        }
      })
      .catch((error) => {
        setMessageSending(false)

        const errorMessage =
          error.response && error.response.data
            ? error.response.data
            : error.toString()

        setMessageError(`An error occurred - ${errorMessage}`)
        trackFormError(`SuppportTicket - ${errorMessage}`)
      })
  }

  return (
    <>
      {messageSuccess && (
        <div className="u-inner">
          <section className="c-section c-support-thank-you">
            <div className="u-inner u-inner--800">
              <div className="u-text-centre c-support-box">
                <img src="/images/support/thumbs-up.png" width="120px" height="120px" />
                <h1 className="h h3">Thanks!</h1>
                <h2 className="h h3">We’ll get back to you soon at</h2>
                <h2 className="h h3 u-bgcolor-gradient u-bgcolor-gradient-text">{email}</h2>
              </div>
            </div>
          </section>
        </div>
      )}

      {!messageSuccess && (
        <>
          <span className="c-support-form__required">Please fill in all fields</span>

          <form
            action=""
            className="c-support-form"
            encType="multipart/form-data"
            method="post"
            onSubmit={submitHandler}
          >
            <div className="c-form-field">
              <label className="c-form-label" htmlFor="full-name">
                Your full name
              </label>
              <input
                autoComplete="name"
                id="full-name"
                name="fullname"
                required
                type="text"
                value={fullName}
                onChange={(event) => {
                  setFullName(event.target.value)
                }}
              />
            </div>
            <div className="c-form-field">
              <label className="c-form-label" htmlFor="subject">
                How can we help you today?
              </label>
              <span className="c-form-hint">
                This helps us find you the right answer, quicker.
              </span>
              <select
                id="subject"
                value={subject}
                onChange={event => {
                  setSubject(event.target.value)
                }}
                onBlur={event => {
                  setSubject(event.target.value)
                }}
              >
                <option value="">Pick an option that best describes your issue</option>
                <option>Problems accessing my account</option>
                <option>I have a question before I signup</option>
                <option>I want to understand how something works</option>
                <option>Not receiving emails</option>
                <option>A billing or invoicing question</option>
                <option>I have a feature suggestion</option>
                <option>Something looks broken</option>
                <option>Other</option>
              </select>
            </div>
            <div className="c-form-field">
              <label className="c-form-label" htmlFor="message">
                Please describe your issue in as much detail as you can
              </label>
              <span className="c-form-hint">
                The more we know, the better we can help you.
              </span>
              <textarea
                cols="5"
                id="message"
                minLength="5"
                name="message"
                rows="5"
                required
                value={message}
                onChange={(event) => {
                  setMessage(event.target.value)
                }}
              />
            </div>
            <div className="c-form-field">
              <label className="c-form-label" htmlFor="email">
                Your email address
              </label>
              <span className="c-form-hint">
                Please double check your email is correct.
              </span>
              <input
                autoComplete="work email"
                id="email"
                name="email"
                required
                type="email"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
              />
            </div>
            <div className="c-form-field c-form-field--buttons">
              <button
                className="c-button c-button--xl c-button--primary"
                data-label="SupportTicket"
                disabled={!message || !fullName || !email || messageSending}
                type="submit"
              >
                {messageSending
                  ? `Sending support request…`
                  : `Send support request`}
              </button>

              {messageError && (
                <p className="c-form-error">{messageError}</p>
              )}
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default SupportForm
